import React from 'react'
import { Link } from 'gatsby'

const ContactInfo = () => {
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <div className="icon">
                                
                                <i className='bx bx-map'> </i>
                            </div>
                            <h3 style={{ color: '#0F477C' }}>Our Address</h3>
                            <p >Bumi Nusantara Group </p>
                            <p>
                                Prosperity Tower, Level 50 F
                                District 8, SCBD Lot 28
                                Jl. Jend Sudirman Kavling 52-53
                                Jakarta 12190, Indonesia</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3 style={{ color: '#0F477C' }}>Contact</h3>
                            <p>Phone: <a href="tel:+44457895789">(+6221) - 501 238 88</a></p>
                            <p>Fax: <a href="tel:+44457895789">(+6221) - 501 588 88</a></p>
                            <p>E-mail: <a href="mailto:hello@rewy.com">info@buminusa.co.id</a></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <h3 style={{ color: '#0F477C' }}>Office Hours</h3>
                            <p>Monday - Friday: 08:00 - 17:00</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo